





























































import { Component, Vue } from "vue-property-decorator";
import "@/modules/Helpers";
import Helpers from "@/modules/Helpers";

@Component
export default class QuantityItems extends Vue {
  isQrScannerOpen = false;
  isBusy = true;
  searchQuery = "";
  stocktaking: any = {};
  page = 1;
  limit = 10;
  count = 1;
  items = [] as any[];
  fields = [
    { key: "rnu", label: this.$i18n.translate("Rental article") },
    { key: "rna", label: this.$i18n.translate("Name") },
    //{ key: 'dna', label: this.$i18n.translate('Depot')          },
    { key: "qty", label: this.$i18n.translate("Quantity") },
  ];

  get isCommited() {
    return this.stocktaking.sta === 2;
  }

  async mounted() {
    await this.$nextTick();

    const json = await this.$store.state.hyrma.client._client.get(
      `/HyrmaMobile/RentalObjectStocktaking/JsonInfo/${this.$route.params.id}?inventoryType=2`
    );
    this.stocktaking = json.data.data;
    await this.reloadSearch();
  }

  showQrScanner() {
    this.isQrScannerOpen = true;
  }

  hideQrScanner() {
    this.isQrScannerOpen = false;
  }

  onQrScannerDecode(result: string) {
    this.searchQuery = result;
    this.hideQrScanner();
    this.reloadSearch();
  }

  async reloadSearch() {
    this.page = 1;
    this.reloadPage();
  }

  async reloadPage() {
    await this.$nextTick();

    this.isBusy = true;
    const items = await this.reloadInventoryItems();
    await this.reloadQuantities(items);
    this.isBusy = false;
  }

  async reloadInventoryItems() {
    const offset = this.limit * (this.page - 1);
    const params = {
      inventoryType: [2],
      depot: this.$route.query.depot,
      q: this.searchQuery,
      limit: this.limit,
      offset: offset,
      checkFree: false,
    };
    const json = await this.$store.state.hyrma.client._client.get(
      "/HyrmaMobile/InventoryItem/List",
      { params }
    );
    const res = json.data.data;
    const items = res.items as any[];

    this.count = res.count;
    let merged = this.items;
    if (this.count != merged.length) merged = new Array(this.count).fill({});
    this.items = [
      ...merged.slice(0, offset),
      ...items,
      ...merged.slice(offset + items.length),
    ];
    return items;
  }

  async reloadQuantities(items: any[]) {
    const params = {
      inventoryType: [2],
      inventoryItems: items.map((i) => i.id) as number[],
      stocktaking: this.$route.params.id,
      limit: items.length,
      offset: 0,
    };
    const res = await this.$store.state.hyrma.client._client.get(
      "/HyrmaMobile/RentalObjectStocktakingItem/List/",
      { params }
    );
    const qtys = res.data.data;
    for (let qty of qtys)
      for (let item of this.items) {
        if (item.id !== qty.iid) continue;
        item.qty = qty.qty;
      }
  }

  async onQtyChange(item: any) {
    const params = {
      id: this.stocktaking.id,
      depot: this.$route.query.depot,
      inventoryItem: item.id,
      qty: item.qty,
    };
    const res = await this.$store.state.hyrma.client._client.post(
      "/HyrmaMobile/RentalObjectStocktaking/SetInventoryItemQty",
      params
    );
    if (res.data.err) return;
  }
}
