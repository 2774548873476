































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DiffList extends Vue {
  isBusy = true;
  items = [] as any[];
  page = 1;
  limit = 20;
  count = 1;
  fields = [
    { key: 'inu', label: this.$i18n.translate('Inventory item') },
    { key: 'rnu', label: this.$i18n.translate('Rental article') },
    { key: 'rna', label: this.$i18n.translate('Name') },
    { key: 'dna', label: this.$i18n.translate('Depot') },
  ];

  async mounted() {
    this.reload();
  }

  async reload() {
    await this.$nextTick();

    this.isBusy = true;
    const offset = this.limit * (this.page - 1);

    const params = {
      stocktaking: this.$route.params.id,
      inventoryType: [0, 3],
      depot: this.$route.query.depot,
      offset: offset,
      limit: this.limit,
    };
    const json = await this.$store.state.hyrma.client._client.get('/HyrmaMobile/RentalObjectStocktakingItem/DiffList', { params });
    const res = json.data.data;
    const items = res.items as any[];

    this.count = res.count;
    let merged = this.items;
    if (this.count != merged.length)
      merged = new Array(this.count).fill({});
    this.items = [...merged.slice(0, offset), ...items, ...merged.slice(offset + items.length)]

    this.isBusy = false;
  }
}
