export default class Helpers {
    static async sleep(ms: number):Promise<number> { return new Promise(r => setTimeout(r, ms)); }

    static async hasCamera():Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices)
                return resolve(false);
          
            navigator.mediaDevices.enumerateDevices()
            .then(function(devices) {
                const hasAnyVideoInputDevice = devices.some(d => d.kind === 'videoinput');
                resolve(hasAnyVideoInputDevice);
            });
        });
    }
}
