






















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Index extends Vue {
  isBusy = true;
  stocktakings = [];
  fields = [
    { key: 'cat', label: this.$i18n.translate('Created at') },
    { key: 'cby', label: this.$i18n.translate('Created by') },
    { key: 'sta', label: this.$i18n.translate('Status') },
    { key: 'siq', label: this.$i18n.translate('Stocktaking items') },
  ];

  async stocktakingSelected(record: any, idx: number) {
    this.$router.push(`/RentalObjectStocktaking/${record.id}/SelectDepot`);
  }

  async mounted() {
    await this.$nextTick();
    const res = await this.$store.state.hyrma.client._client.get('/Hyrma/RentalObjectStocktaking/Index_SlickGrid');
    this.isBusy = false;
    this.stocktakings = res.data.data;
  }
}
