































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class IndividualItems extends Vue {  
  isQrScannerOpen    = false;
  isBusy             = true;
  searchQuery        = '';
  autoCompleteItems  = [] as any[];
  stocktaking:any    = {};
  items              = [] as any[];
  autoCompleteFields = [
      { key: 'inu', label: this.$i18n.translate('Inventory item') },
      { key: 'rnu', label: this.$i18n.translate('Rental article') },
      { key: 'rna', label: this.$i18n.translate('Name')           },
      { key: 'dna', label: this.$i18n.translate('Depot')          },
  ];
  fields = [
      { key: 'inu', label: this.$i18n.translate('Inventory item') },
      { key: 'rnu', label: this.$i18n.translate('Rental article') },
      { key: 'rna', label: this.$i18n.translate('Name')           },
      { key: 'del', label: ''                                     },
  ];
  
  get isCommited() {
    return this.stocktaking.sta === 2;
  }

  showQrScanner() {
    this.isQrScannerOpen = true;
  }

  hideQrScanner() {
    this.isQrScannerOpen = false;
  }

  onQrScannerDecode(result: string) {
    this.searchQuery = result;
    this.hideQrScanner();
    this.updateAutocomplete();
  }

  async mounted() {
    await this.$nextTick();
    await this.loadStocktaking();
    await this.loadItems();
    this.isBusy = false;
  }

  async loadStocktaking() {
    const params = { inventoryType: [0, 3] };
    const res = await this.$store.state.hyrma.client._client.get(`/HyrmaMobile/RentalObjectStocktaking/JsonInfo/${this.$route.params.id}`, { params });
    this.stocktaking = res.data.data;
  }

  async loadItems() {
    const params = { inventoryType: [0, 3], stocktaking: this.$route.params.id, depot: this.$route.query.depot };
    const res = await this.$store.state.hyrma.client._client.get('/HyrmaMobile/RentalObjectStocktakingItem/List/', { params });
    this.items = res.data.data;
  }

  async onSelect(a: any[]) {
    this.addItem(a[0].id);
  }

  async updateAutocomplete() {
    this.autoCompleteItems = [];
    if (this.searchQuery === '')
      return;
    const params = {
      limit: 10,
      inventoryType: [0, 3],
      q: this.searchQuery,
      excludeStocktaking: this.$route.params.id,
      checkFree: false,
    };
    let res = await this.$store.state.hyrma.client._client.get('/HyrmaMobile/InventoryItem/List', { params });
    this.autoCompleteItems = res.data.data.items;
  }

  async addItem(itemId: number) {
    const params = { id: this.stocktaking.id, inventoryItem: itemId, depot: this.$route.query.depot, qty: 1 };
    let res = await this.$store.state.hyrma.client._client.post('/HyrmaMobile/RentalObjectStocktaking/SetInventoryItemQty', params);
    if (res.data.err) return console.log(res.data.err);

    this.items = [res.data.data, ...this.items];
    this.searchQuery = '';
    this.autoCompleteItems = [];
    (this.$refs['main-input'] as HTMLInputElement).focus();
  }

  async removeItem(item: any) {
    const params = { id: this.stocktaking.id, inventoryItem: item.iid, depot: this.$route.query.depot, qty: null };
    let res = await this.$store.state.hyrma.client._client.post('/HyrmaMobile/RentalObjectStocktaking/SetInventoryItemQty', params);
    if (res.data.err) return console.log(res.data.err);

    const ix = this.items.indexOf(item);
    this.items.splice(ix, 1);
  }
}
